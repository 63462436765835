import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Podpovrchová voda (půdní a podzemní)" />
    <h1>Podpovrchová voda (půdní a podzemní)</h1>
    <p><strong>Podpovrchov&aacute; voda</strong> je voda vyskytuj&iacute;c&iacute; se v&nbsp;zemsk&eacute; kůře ve v&scaron;ech skupenstv&iacute;ch v&nbsp;př&iacute;m&eacute;m kontaktu s&nbsp;horninov&yacute;m prostřed&iacute;m. Jin&yacute;mi slovy jde o vodu pod zemsk&yacute;m povrchem a společn&eacute; označen&iacute; pro vodu půdn&iacute; a podzemn&iacute;.</p>
    <p><strong>Půdn&iacute; voda</strong> se nach&aacute;z&iacute; v&nbsp;půdě v&nbsp;<strong>p&aacute;smu provzdu&scaron;něn&iacute;</strong> (aerace) &ndash; tedy v&nbsp;p&aacute;smu, v&nbsp;němž se spolu s&nbsp;vodou vyskytuje i vzduch. <strong>Podzemn&iacute; voda</strong> je pak naopak v&nbsp;<strong>p&aacute;smu nasycen&iacute;</strong> (saturace) v&nbsp;němž jsou v&scaron;echny dutiny a p&oacute;ry zaplněny vodou. Tyto dvě p&aacute;sma pak odděluje tzv. <strong>hladina podzemn&iacute; vody</strong> (zkr&aacute;ceně HPV).</p>
    <Hydroo1 />
    <hr /><h2>Zdroje vzniku a doplňov&aacute;n&iacute; půdn&iacute; a podzemn&iacute; vody</h2>
    <p>Zdrojem podpovrchov&eacute; vody je nejčastěji voda obsažen&aacute; v&nbsp;atmosf&eacute;ře. V&nbsp;takov&eacute;m př&iacute;padě mluv&iacute;me o <strong>vad&oacute;zn&iacute; vodě</strong>. Ta do dutin a p&oacute;rů půdy vstupuje buď jako ochlazen&aacute; a kondenzovan&aacute; vodn&iacute; p&aacute;ra ze vzduchu (<strong>kondenzačn&iacute; voda)</strong>, či se do půdy dost&aacute;v&aacute; vsakov&aacute;n&iacute;m sr&aacute;žek a vody tekouc&iacute; po povrchu (<strong>infiltračn&iacute; voda</strong>).</p>
    <p>V&nbsp;druh&eacute;m př&iacute;padě je zdrojem vody kondenzace vystupuj&iacute;c&iacute; vodn&iacute; p&aacute;r, kter&aacute; vznik&aacute; sloučen&iacute;m molekul vod&iacute;ku a kysl&iacute;ku hluboko v&nbsp;zemsk&eacute; kůře. Pak mluv&iacute;me o <strong>juveniln&iacute; vodě</strong>.</p>
    <Hydroo2 />
    <hr /><h2>Vlastnosti horninov&eacute;ho prostřed&iacute;</h2>
    <p>Prvn&iacute; vlastnost&iacute; horninov&eacute;ho prostřed&iacute; je <strong>propustnost</strong>, ta se vztahuje na schopnost horniny propou&scaron;tět kapaliny, jako např&iacute;klad vodu či např&iacute;klad ropu, přes p&oacute;ry, pukliny a jin&eacute; dutiny př&iacute;tomn&eacute; v&nbsp;hornině. Rozli&scaron;ujeme:</p>
    <ul>
    <li><u>mezizrnovou</u> (charakteristick&aacute; pro p&oacute;rovit&eacute; sedimenty a sediment&aacute;rn&iacute; horniny obecně),</li>
    <li><u>puklinovou</u> (horniny s&nbsp;př&iacute;tomnost&iacute; puklin a trhlinek, obyčejně bez voln&yacute;ch mezizrnov&yacute;ch prostor &ndash; vět&scaron;ina magmatick&yacute;ch a metamorfovan&yacute;ch hornin),</li>
    <li><u>dutinovou či krasovou propustnost</u> (nejčastěji v&nbsp;lehko propustn&yacute;ch hornin&aacute;ch, předev&scaron;&iacute;m v&aacute;penc&iacute;ch, v&nbsp;kter&yacute;ch chemick&yacute;m rozpou&scaron;těn&iacute;m často vznikaj&iacute; i rozs&aacute;hlej&scaron;&iacute; podzemn&iacute; dutiny).</li>
    </ul>
    <p><strong>P&oacute;rovitost</strong> je procentu&aacute;ln&iacute; pod&iacute;l objemu horniny či sedimentu, kter&yacute; je nevyplněn&yacute; (voln&yacute;) a obsahuje p&oacute;ry. Vyjadřuje m&iacute;ru schopnosti horniny udržet v&nbsp;těchto otevřen&yacute;ch prostorech podzemn&iacute; vodu. Např&iacute;klad u j&iacute;lů b&yacute;v&aacute; p&oacute;rovitost vysok&aacute; (mezi 35 a 80 %), zat&iacute;mco nejniž&scaron;&iacute; b&yacute;v&aacute; u krystalick&yacute;ch hornin (0 až 10 %).</p>
    <p><strong>Průtočnost</strong> je vyj&aacute;dřena součinem koeficientu propustnosti a tlou&scaron;ťkou dan&eacute; zvodněl&eacute; vrstvy. Posuzuje tak defacto vlastnost cel&eacute; zkouman&eacute; zvodněl&eacute; vrstvy propou&scaron;tět vodu.</p>
    <p>Pojem <strong>vodn&iacute; kapacita</strong> vyjadřuje schopnost prostřed&iacute; přijmout a udržet určit&eacute; množstv&iacute; vody, pokud mluv&iacute;me o <strong>pln&eacute; vodn&iacute; kapacitě</strong>, znamen&aacute; to, že v&scaron;echny voln&eacute; prostory v&nbsp;prostřed&iacute; jsou zaplněny vodou. Množstv&iacute; vody, kter&eacute; je prostřed&iacute; schopn&eacute; udržet po předch&aacute;zej&iacute;c&iacute;m nasycen&iacute; označujeme jako <strong>retenčn&iacute; vodn&iacute; kapacita</strong>.</p>
    <hr /><h2>Půdn&iacute; voda</h2>
    <p>Půdn&iacute; voda (p&aacute;smo aerace) se vyskytuje ve v&scaron;ech 3 skupenstv&iacute;ch; z&nbsp;hlediska trv&aacute;n&iacute; rozli&scaron;ujeme <strong>trval&eacute; a přechodn&eacute; druhy půdn&iacute; vody</strong>.</p>
    <h3>Trval&eacute; druhy půdn&iacute; vody</h3>
    <ul>
    <li><strong>Vodn&iacute; p&aacute;ra </strong>zaplňuje pukliny a p&oacute;ry, vznik&aacute; vypařov&aacute;n&iacute;m tekut&eacute; vody v&nbsp;půdě při zv&yacute;&scaron;en&iacute; teploty.</li>
    <li><strong>Adsorpčn&iacute; (adhezn&iacute;) voda </strong>tvoř&iacute; na povrchu zrn či puklin nesouvisl&eacute; nebo souvisl&eacute; vrstvičky vody s&nbsp;tlou&scaron;ťkou 5&ndash;15 mm. K&nbsp;zrnům, resp. stěn&aacute;m puklin, je připout&aacute;na fyzik&aacute;ln&iacute;mi silami &ndash; adsorpčn&iacute;mi. Tvoř&iacute; se z&nbsp;vodn&iacute; p&aacute;ry, ale i z&nbsp;infiltruj&iacute;c&iacute; vody.</li>
    <li><strong>Kapil&aacute;rn&iacute; voda </strong>zaplňuje p&oacute;ry (kapil&aacute;ry &ndash; dlouh&eacute;, &uacute;zk&eacute;) s&nbsp;tlou&scaron;ťkou maxim&aacute;lně 1 mm či pukliny s&nbsp;průměrem 0,25 mm. Je typick&aacute; pro jemnozrnn&eacute; materi&aacute;ly a vyskytuje se v&nbsp;bezprostředn&iacute; bl&iacute;zkosti hladiny podzemn&iacute; vody (HPV) &ndash; tj. <strong>p&aacute;smo kapil&aacute;rn&iacute;ho zdvihu</strong> (vzl&iacute;n&aacute;n&iacute;). V&nbsp;př&iacute;padě, že vyplňuje prostory nad HPV, mluv&iacute;me o <strong>podepřen&eacute; kapil&aacute;rn&iacute; vodě</strong>, pokud HPV v&scaron;ak poklesne, může vznikat <strong>zavě&scaron;en&aacute; kapil&aacute;rn&iacute; voda</strong>.</li>
    </ul>
    <h3>Přechodn&eacute; druhy podzemn&iacute; vody</h3>
    <ul>
    <li><strong>Vsakuj&iacute;c&iacute; voda</strong> pronik&aacute; z&nbsp;povrchu půdy hlouběji vlivem gravitačn&iacute;ch sil, a to puklinami, p&oacute;ry, voln&yacute;mi prostorami po kořenech rostlin, chodbami živočichů atd. Po dosažen&iacute; HPV doplňuje vodu kapil&aacute;rn&iacute;.</li>
    <li><strong>Půdn&iacute; led</strong> vznik&aacute; při teplot&aacute;ch pod 0 &deg;C. Nejprve se tvoř&iacute; jako jinovatka půdn&iacute;ho vzduchu, drobn&eacute; ledov&eacute; krystalky v&scaron;ak mohou narůstat ochlazov&aacute;n&iacute;m a přimrz&aacute;n&iacute;m vystupuj&iacute;c&iacute; kapil&aacute;rn&iacute; či adsorpčn&iacute; vody.</li>
    </ul>
    <hr /><h2>Podzemn&iacute; voda</h2>
    <p>V&yacute;skyt podzemn&iacute; vody (p&aacute;smo saturace) je podm&iacute;něn existenc&iacute; takov&eacute; horniny, kter&aacute; m&aacute; schopnost vodu přijmout, ale takt&eacute;ž i odevzd&aacute;vat. Mus&iacute; obsahovat p&oacute;ry a pukliny, kter&eacute; jsou <strong>vět&scaron;&iacute; než kapil&aacute;ry</strong>. Pohyb podzemn&iacute; vody je vyvol&aacute;n v&yacute;hradně <strong>gravitačn&iacute; silou</strong>, přen&aacute;&scaron;&iacute; <strong>hydrostatick&yacute; tlak</strong>, a tak přirozeně pronik&aacute; do vět&scaron;&iacute;ch hloubek. Ve vertik&aacute;ln&iacute;m směru rozli&scaron;ujeme <strong>3 p&aacute;sma podzemn&iacute; vody</strong>:</p>
    <ul>
    <li><u>vrchn&iacute; p&aacute;smo</u> (nejintenzivněj&scaron;&iacute; kontakt povrchov&eacute; a podzemn&iacute; hydrosf&eacute;ry, voda přev&aacute;žně sladk&aacute; a slabě mineralizovan&aacute;; tzv. prost&eacute; vody sladk&eacute;, slabomineralizovan&eacute;),</li>
    <li><u>středn&iacute; p&aacute;smo</u> (zpomalen&aacute; v&yacute;měna &ndash; vět&scaron;&iacute; mineralizace při pobytu v&nbsp;horninov&eacute;m prostřed&iacute; s&nbsp;vy&scaron;&scaron;&iacute; teplotou; tzv. term&aacute;ln&iacute; vody s&iacute;rnat&eacute;, hork&eacute; vody),</li>
    <li><u>spodn&iacute; p&aacute;smo</u> (velmi zpomalen&aacute; v&yacute;měna vod, kter&aacute; podmiňuje silnou mineralizaci; často se v&nbsp;něm vyskytuj&iacute; slan&eacute; a hyperterm&aacute;ln&iacute; vody, typicky z&aacute;pach).</li>
    </ul>
    <p>Z&nbsp;pohledu <strong>podm&iacute;nek zvodněn&eacute; vrstvy</strong> rozli&scaron;ujeme podzemn&iacute; vodu s&nbsp;<strong>volnou hladinou</strong>. V&nbsp;takov&eacute;m př&iacute;padě nadložn&iacute; vrstva bezprostředně netlač&iacute; na hladinu podzemn&iacute; vody a ta tak může kol&iacute;sat při norm&aacute;ln&iacute;m tlaku. Př&iacute;kladem jsou zanořen&eacute; potoky v&nbsp;Moravsk&eacute;m krasu (Punkva, Jedovnick&yacute; potok).</p>
    <p>D&aacute;le rozli&scaron;ujeme podzemn&iacute; vodu s&nbsp;<strong>napjatou hladinou</strong>, tj. art&eacute;zskou vodu. Nadložn&iacute; vrstva d&iacute;ky prohnut&iacute; tlač&iacute; na HPV a hladina je tak pod tlakem. Po naru&scaron;en&iacute; t&eacute;to vrstvy okamžit&eacute; doch&aacute;z&iacute; k&nbsp;v&yacute;stupu podzemn&iacute; vody nad &uacute;roveň HPV. Pokud vystoup&iacute; až na zemsk&yacute; povrch, mluv&iacute;me o <strong>pozitivn&iacute; v&yacute;stupn&iacute; v&yacute;&scaron;ce</strong> (využit&iacute; pro tzv. art&eacute;zsk&eacute; studně), pokud ne, jde o <strong>negativn&iacute; v&yacute;stupn&iacute; v&yacute;&scaron;ku</strong>.</p>
    <p>Specifick&yacute; v&yacute;skyt <u>hladiny podzemn&iacute; vody (HPV)</u>, kter&aacute; vystupuje až na povrch, je pak např&iacute;klad v pou&scaron;tn&iacute;ch o&aacute;z&aacute;ch. Infiltračn&iacute; oblast je často vzd&aacute;len&aacute; des&iacute;tky až stovky kilometrů od takov&eacute;ho m&iacute;sta. Z&aacute;kladn&iacute; podm&iacute;nkou pro vznik takov&eacute; o&aacute;zy v pou&scaron;ti je existence poruchy v&nbsp;nadložn&iacute; vrstvě, kter&aacute; HPV vytlač&iacute; k&nbsp;povrchu.</p>
    <h3>&Uacute;roveň hladiny podzemn&iacute; vody</h3>
    <p>Při sledov&aacute;n&iacute; HPV sledujeme předev&scaron;&iacute;m jej&iacute; <strong>&uacute;roveň</strong>, a to buď <strong>absolutně</strong> nadmořskou v&yacute;&scaron;kou, či <strong>relativně</strong> jej&iacute; hloubkou pod povrchem a měř&iacute;me ji tzv. <strong>piezometrem</strong>. Tuto &uacute;roveň často vyjadřujeme na map&aacute;ch formou různ&yacute;ch izočar:</p>
    <ul>
    <li><strong>hydroizohypsa</strong> = spojuje stejnou &uacute;roveň hladiny podzemn&iacute; vody dle nadmořsk&eacute; v&yacute;&scaron;ky</li>
    <li><strong>hydroizobata</strong> = spojuje stejnou &uacute;roveň hladiny podzemn&iacute; vody dle hloubky pod povrchem</li>
    <li><strong>hydroizopieza</strong> = spojuje stejnou &uacute;roveň piezometrick&eacute; hladiny podzemn&iacute; vody dle nadmořsk&eacute; v&yacute;&scaron;ky (v&yacute;&scaron;ka, kam by podzemn&iacute; voda s&nbsp;napjatou hladinou pod tlakem vystoupala, kdyby ji v&nbsp;tom nebr&aacute;nilo nadlož&iacute;)</li>
    </ul>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Podpovrchová voda (půdní a podzemní)</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/protipovodnova-opatreni/"><button className="inv">&larr; Protipovodňová opatření</button></Link>
    <Link to="/hydrologie/prameny-mineralni-vody/"><button className="inv">Prameny a minerální voda &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
